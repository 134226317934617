<template>
  <div class="login">
    <van-image class="login-head"
               :src="projectInfo.logo||$config.defaultLogo"/>
    <van-form @submit="onSubmit">
      <div class="login-li">
        <van-field
          v-model="mobile"
          name="mobile"
          maxlength="11"
          label="账号"
          type="tel"
          center
          placeholder="请输入登录账号"
          @update:model-value="checkMobile"
        >
          <template #extra>
            <span class="login-close" v-if="show_close" @click="inputClose"></span>
          </template>
        </van-field>
        <van-field
          v-model="password"
          :type="show_password ? 'number': 'password'"
          name="password"
          maxlength="20"
          label="密码"
          placeholder="请输入登录密码"
          v-if="loginType === 'password'"
        >
          <template #extra>
            <span :class="[show_password ? 'login-open': 'login-hide']" @click="passwordHide"></span>
          </template>
        </van-field>
        <van-field
          v-model="code"
          type="number"
          name="code"
          maxlength="20"
          label="验证码"
          placeholder="请输入验证码"
          v-if="loginType === 'code'"
        >
          <template #extra>
            <template v-if="show_send_code">
              <div class="login-send" @click="sendCode">
                发送验证码
              </div>
            </template>
            <template v-else>
              <div class="login-send--a">
                <van-count-down :time="time" format="ss" @finish="timeFinish"/>
                秒后重新发送
              </div>
            </template>

          </template>
        </van-field>
      </div>
      <div class="login-tip" v-if="loginType == 'password'">
        <span class="login-tip--left" @click="CheckCode">验证码登录</span>
        <span @click="pageJump('/password')">忘记密码?</span>
      </div>
      <div class="login-tip" v-if="loginType == 'code'">
        <span class="login-tip--left" @click="CheckPassword">密码登录</span>
        <span @click="pageJump('/tips')">收不到验证码?</span>
      </div>


      <div class="global-button">
        <van-button block type="primary" native-type="submit">
          登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>

  import {reactive, ref, toRefs, onMounted, computed,inject,} from 'vue'
  import { useStore } from 'vuex'
  import router from '../router'
  import to from 'await-to-js';
  import {sendSms,userLogin} from '@/api/user'
  import usePage from '@/hooks/usePage'
  import {Toast} from 'vant'
  // import md5 from 'js-md5'
  // import {WxPage} from '../enum'

  import {setLocal, getLocal } from '@/common/js/utils'

  export default {
    setup() {
      const store = useStore()
      const _debounce = inject('debounce');
      const {switchTabWx, navigateTo} = usePage()
      const TIME = 60 * 1000;
      const verifyRef = ref(null)
      const state = reactive({
        ajaxing: false,
        mobile: '',
        password: '',
        loginType: 'password',
        code: '',
        see_icon: false,
        projectInfo: {},
        show_close: false,
        show_password: false,
        show_send_code: true,//显示发送验证码
        time: 0,

      })

      const isInWxapp = ref(false)
      const {isMiniProgram} = usePage()
      // let { userLogin } =  mapActions(['userLogin'])

      onMounted(async () => {
        isInWxapp.value = await isMiniProgram()
        let {project_name} = state.projectInfo;
        if (!!project_name) {
          document.title = state.projectInfo.project_name
        }
        state.projectInfo = computed(() =>  store.getters.projectInfo);
      })
      let methods = {

        checkMobile(value) {
          if (!!value && !state.show_close) {
            state.show_close = true
          } else if (!value && state.show_close) {
            state.show_close = false
          }
        },

        inputClose() {
          state.mobile = "";
          state.show_close = false
        },

        passwordHide() {
          state.show_password = !state.show_password;
        },
        CheckCode() {
          state.loginType = "code";
        },
        CheckPassword() {
          state.loginType = "password";
        },
        timeFinish() {
          state.show_send_code = true;
          state.time = 0;
        },
        async sendCode() {
          if (state.time === TIME) {
            return false;
          }
          let mobile = state.mobile;
          if (!mobile) {
            Toast('请输入账号');
            return false;
          }
          mobile = mobile.trim();
          let [err, res] = await to(sendSms({mobile}))
          if (res) {
            Toast(res.msg || "验证码发送成功");
            state.show_send_code = false;
            state.time = TIME;
          }
        },
      }


      function pageJump(path) {
        navigateTo(path)
      }

      async function login(values) {
        if(state.ajaxing){
          return false;
        }
        let {mobile, password, code} = state;
        let loginType = state.loginType;
        if (!mobile) {
          Toast("请输入登录账号");
          return false;
        }
        if (loginType === "password" && !password) {
          Toast("请输入登录密码");
          return false;
        }
        if (loginType === "code" && !code) {
          Toast("请输入验证码");
          return false;
        }

        let params = {
          mobile,
          password,
          loginType,
          code,
        };
        state.ajaxing = true;
        let [err, res] = await store.dispatch('userLogin', params)
        if (res?.code === 0) {
          // Toast(res.msg || "登录成功");
          state.ajaxing = false;
          router.push('/home')
        }
        if (err) {
          state.ajaxing = false;
        }
      }

      // 提交登录或注册表单
      const onSubmit = _debounce(login,300,true)

      return {
        ...toRefs(state),
        ...methods,
        onSubmit,
        isInWxapp,
        pageJump,

      }
    },
    mounted() {

    }
  }
</script>

<style lang="less">
  @import '../common/style/base/base';
  @import '../common/style/login';

  .login {
    background: #FFFFFF;
    padding: 45px 22px 0;
  }

  .login-head {
    display: block;
    .wh(100px, 100px);
    margin: 0 auto 60px;
    .borderRadius(50%)
  }

  .login-li {
    .login__it {
      .disFlexA()
    }
  }


  .login-close {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    .wh(24px, 24px);
    .norepBg('input-close.png')
  }

  .login-open {
    display: inline-block;
    position: relative;
    .wh(22px, 22px);
    .norepBg('input-open.png')

  }

  .login-hide {
    display: inline-block;
    position: relative;
    .wh(22px, 22px);
    .norepBg('input-hide.png')
  }

  .login-view {
    .disFlexA()
  }


  .login-send {
    background: #000;
    height: 24px;
    line-height: 24px;
    .fontcolor(11px, #fff);
    padding: 0 10px;
    .borderRadius(12px)
  }

  .login-send--a {
    background: @btnBg;
    .disFlexA();
    .fontcolor(11px, @basicTextColor);
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    .borderRadius(12px)
  }


 .global-button {
    background: #000000;
    color: #ffffff;
    font-weight: bold;
    margin: 50px 0.4667rem 0;
    &:deep(.van-button--primary){
      background: #000000;
      color: #ffffff;
    }

  }


  .login-tip{
    position: relative;
    margin: 0 auto;
    padding: 15px 16px;
    .disFlexAJ(center, space-between);
    .fontcolor(13px, #000000);
    line-height: 22px;
    ::after{
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: var(--van-padding-md);
      top: 0;
      left: var(--van-padding-md);
      border-top: 0.02667rem solid var(--van-cell-border-color);
      transform: scaleY(.5);
    }
    .login-tip--left {
    }
  }
</style>
